/****************Admin*******************/
.AdminMContainer {
    font-family: 'Work Sans', sans-serif;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    background-color: #f3f3f3;
    transition: 300ms;
}

/*Link to JS*/
.Sideheader {
    width: 6%;
}

/*Link to JS*/
.Sideheader1 {
    width: 18%;
}



/*Link to JS*/
.Sideheader a {
    text-decoration: none;
}

/*Link to JS*/
.Sideheader1 a {
    text-decoration: none;

}

.btn2 {
    width: 100%;
    height: 10vh;
    background-color: transparent;
    color: #d7d7d7;
    border: none;
    font-size: 22px;
    outline: none;
    padding: 0 20px;
    background-color: #313131;
    cursor: pointer;
    margin-bottom: 10px;
}

.btn2:hover {
    background-color: #1fcc68;
    color: white;
}

.btn2:focus {
    outline: none;
}


.Sideheader ul {
    width: 100%;
    text-decoration: none;
    list-style: none;
    padding: 0;
}

.Sideheader ul li {
    margin: 5px 6px;
}

.anchor {
    overflow: hidden;
    border-radius: 10px;
    justify-content: center;
    background-color: #d7d7d7;
    display: flex;
    width: 15vw;
    transition: 300ms;
    width: 100%;
    height: 10vh;
    align-items: center;
    padding: 0 10%;
    color: #444;
    outline: none;
}

.anchor:hover {
    color: #ffffff;
    background-color: #18B259;
}

.anchor:hover .name1 {
    color: #ffffff;
}

.anchor>i {
    font-size: 20px;
}


.btnAnchor {
    overflow: hidden;
    border-radius: 10px;
    justify-content: center;
    background-color: #d7d7d7;
    display: flex;

    transition: 300ms;
    width: 200px;
    height: 55px;
    align-items: center;
    /* padding: 0 10%;*/
    color: #444;
    outline: none;
    margin-top: 30px;
}

.btnAnchor:hover {
    color: #ffffff;
    background-color: #18B259;
}

.btnAnchor:hover .name1 {
    color: #ffffff;
}

.btnAnchor>i {
    font-size: 20px;
}


.lnkAnchor {
    overflow: hidden;
    border-radius: 10px;
    justify-content: center;
    background-color: #d7d7d7;
    display: flex;
    transition: 300ms;
    width: 200px;
    height: 55px;
    align-items: center;
    /*padding: 0 10%;*/
    color: #444;
    outline: none;
    margin-top: 30px;
    border: 2px solid #000;
}

.lnkAnchor:hover {
    color: #ffffff;
    background-color: #18B259;
}

.lnkAnchor:hover .name1 {
    color: #ffffff;
}

.btnAnchor>i {
    font-size: 20px;
}

/*Link to JS*/
.name {
    width: 60%;
    display: none;
    margin: 0 5px;
}

/*Link to JS*/
.name1 {
    width: 100%;
    margin: 0 0 0 15px;
    display: block;
    font-weight: 400;
    transition: 300ms;
}

/*Link to JS*/
.bodyContainer {
    width: 94%;
    min-height: 100vh;
    background-color: #ffffff;
    font-family: 'Work Sans', sans-serif;
    background-attachment: fixed;
    background-size: cover;
    padding: 20px;
}

/*Link to JS*/
.bodyContainer1 {
    width: 82%;
    min-height: 100vh;
    background-color: white;
    padding: 20px;
}

@media (max-width:1000px) {

    /*Link to JS*/
    .Sideheader {
        width: 8%;
    }

    /*Link to JS*/
    .Sideheader1 {
        width: 22%;
    }


    /*Link to JS*/
    .bodyContainer {
        width: 92%;
    }

    /*Link to JS*/
    .bodyContainer1 {
        width: 78%;
    }
}

@media (max-width:830px) {

    /*Link to JS*/
    .Sideheader {
        width: 10%;
    }

    /*Link to JS*/
    .Sideheader1 {
        width: 30%;
    }


    /*Link to JS*/
    .bodyContainer {
        width: 90%;
    }

    /*Link to JS*/
    .bodyContainer1 {
        width: 70%;
    }
}

@media (max-width:600px) {

    /*Link to JS*/
    .Sideheader {
        width: 14%;
    }

    /*Link to JS*/
    .Sideheader1 {
        width: 40%;
    }


    /*Link to JS*/
    .bodyContainer {
        width: 86%;
    }

    /*Link to JS*/
    .bodyContainer1 {
        width: 60%;
    }

    .bt-group {
        flex-flow: column wrap;
    }

    .leftDiv {
        margin-bottom: 20px;
    }

    .rightDiv {
        margin-bottom: 20px;
    }
}

@media (max-width:450px) {

    /*Link to JS*/
    .Sideheader {
        width: 24%;
    }

    /*Link to JS*/
    .Sideheader1 {
        width: 60%;
    }


    /*Link to JS*/
    .bodyContainer {
        width: 82%;
    }

    /*Link to JS*/
    .bodyContainer1 {
        width: 40%;
    }
}

.Adminlogo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Adminlogo img {
    width: 80%;
}

.AdminContainer h2 {
    margin-bottom: 10px;
}

.AdminContainer input {

    margin: 0 0 20px 0;



}

.AdminContainer input[type=text] {
    margin: 0 0 20px 0;
    height: 32px;
}

.AdminContainer input[type=submit] {
    margin: 10px 0 15px 0;
    padding: 12px 25px;
    border-radius: 0px;
}

.AdminContainer .btn {
    margin: 20px 0;
}

.AdminContainer label {
    margin: 0 0 5px 0;
    color: #808080;
}

.AdminContainer a {
    color: #00b6b6;
    text-decoration: none #dddddd;
}

.AdminContainer a:hover {
    color: #297676;
    text-decoration: underline #b5b5b5;
}



.EmptyContainer {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.alignme {
    padding: 0;
    width: 25%;
    display: flex;
    justify-content: end;
}

.text-yo {
    font-size: 15px;
}

.AdminDeleteContainer {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SubDelete {
    width: 50%;
    min-height: 45vh;
    background-color: rgb(221 221 221 / 0.74);
    padding: 30px;
    border-radius: 8px;
    /*margin:10% 0 0 0;*/
}

.SubDelete dt {
    color: #515151;
}

.SubDelete dd {
    font-size: 18px;
    color: #757575;
}

.imgdiv1 {
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

.imgdiv1 img {
    width: 100%;
}

.imgdiv2 {
    width: 180px;
    height: 150px;
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

.imgdiv2 img {
    height: 100%;
}

.EmptyContainer1 {
    width: 100%;
    height: 110px;
}

.fixHeight {
    max-height: 50px;
}

.container1 {
    width: 90%;
    margin: 0 auto;
}


.fm-group {
    width: 100%;
    height: 100%;
}

.fm-group .lbl {
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: #444;
}

.fm-group .fm-control {
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #444;
    outline: none;
}

.bt-group {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.leftDiv {
    width: 30%;
    height: 50px;

}

.rightDiv {
    width: 30%;
    height: 50px;

}


















::selection {
    background-color: rgb(98 98 98 / 0.47);
    color: #fff;
}

.bosterHeading {
    font-size: 80px;
    color: white;
    font-weight: 600;
    font-family: 'Mukta', sans-serif;
}

.text-gray {
    color: rgb(255 255 255 / 0.78);


}

.beforeHeading {
    color: #97d1c3;
    font-weight: 700
}

.text-gray-dark {
    color: #686868
}

.ekonkar {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.ekonkar img {
    width: 100%;
}

.subgray-back {
    background-color: #ebebeb;
}

.testimonialImg {
    width: 85px;
    height: 85px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.menu-bg-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(208, 208, 208, 0.591);
    backdrop-filter: blur(13px);
    padding: 0px 30px;
    position: relative;
    min-height: 80px;
    float: left;
    width: 100%;
    border-radius: 15px;
    -webkit-box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.1);
    border: 1px solid #cfcfcf22;
}

.logoImage {
    width: 70px;

}

@media (max-width:991px) {
    .menu-bg-wrap {
        min-height: 70px;
        border-radius: 13px;


    }

    .logoImage {
        width: 60px;
    }
}


@media (max-width:767px) {
    .menu-bg-wrap {
        min-height: 58px;
        border-radius: 12px;


    }

    .logoImage {
        width: 52px;
    }
}

/************************************************Footer*/
.site-footer {
    background: #1e1b1a;
    font-size: 14px;
    color: #cacaca;
    padding: 70px 0;
}

.site-footer a {
    color: #bebebe;
    position: relative;
    display: inline-block;
    text-decoration: none;
}

.site-footer a:hover {
    color: #eeeeee;
    text-decoration: none;
}

.site-footer a:hover:before {
    background: #1f1f1f;
}

.site-footer .footer-cta h2,
.site-footer .footer-cta .h2 {
    font-size: 30px;
    color: #ffffff;
}

.site-footer .btn:before {
    display: none;
}

.site-footer .widget {
    margin-bottom: 40px;
    display: block;
}

.site-footer .widget h3,
.site-footer .widget .h3 {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #d73b10;
}

.site-footer .widget .links {
    width: 150px;
}

.site-footer .widget .links li {
    margin-bottom: 10px;
}

.site-footer .social li {
    display: inline-block;
}

.site-footer .social li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    background: #53443f;
    border-radius: 40%;
}

.site-footer .social li a:before {
    display: none;
}

.site-footer .social li a>span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.site-footer .social li a:hover {
    background: #201513;
    color: #ffffff;
    border-color: #ffffff8f;
}

/********************************** Home About*/

.homeAbout {
    width: 100%;
    min-height: 30vh;
    display: flex;
    align-items: center;
}

.imageContainer img {
    width: 100%;
}



/****************************************** News*/
.imageHolder {
    width: 100%;
    min-height: 250px;
    overflow: hidden;
    background-position: center;
    background-size: cover;
}

.imageHolder img {
    width: 100%;
}

.box-news {
    border-radius: 2px;
    overflow: hidden
}

.NewsDContainer {
    display: flex;
    min-height: 260px;


    background-color: rgba(235, 197, 189, 0.7);
}

.newsSidePanel {
    background-color: transparent;
    width: 25%;
    height: 100%;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(169, 72, 43);
}

.newsSidePanel span {
    font-size: 50px;

}

.newsSidePanel p {
    line-height: 10px
}

.newsContent {
    width: 75%;
    background-color: white;
    padding: 25px 15px;
}

.ola {
    transition: .3s;
    font-size: 17px;
    color: #515151
}

.linkTag:hover .ola {
    color: #6a1900;

}

.anLink {
    color: #bb7861;
    text-decoration: underline;
}

.anLink:hover {
    color: rgb(210, 166, 149);
    text-decoration: none;
}

.gray-back {
    background-color: #efefef
}



.AdminAlign {
    display: flex;
    margin: 0px 0;
}

.AdminAlign dt {
    width: 20%;
    font: 700;
    color: #353535;
}

.AdminAlign dd {
    width: 80%;
    color: #585858;
}


/****************************************** Text area*/
.text-justify {
    text-align: justify;
}

.text-def {
    color: #0093c3;
    text-decoration: underline;
}

.text-def:hover {
    color: #27caff;
}

.textBoxFix textarea {
    margin: 0 0 20px 0;
    display: block;
    width: 100%;
    min-height: 150px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .textBoxFix textarea {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

.textBoxFix textarea:focus {
    color: #212529;
    background-color: #fff;
    border-color: #8090a5;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 32, 74, 0.25);
    box-shadow: 0 0 0 0.25rem rgba(0, 32, 74, 0.25);
}

.textBoxFix textarea::placeholder {
    color: #6c757d;
    opacity: 1;
}

.text-Greey {
    color: #868686;
}

/*********************************************Team*/
.person {
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.personImgs {
    background-color: #b7c4c4;
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    top: -45px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 2px solid rgba(190, 190, 190, 0.141)
        /*margin: -45px auto 0 auto;*/
}

/*.person img {
        position: relative;
        width: 90px;
        border-radius: 50%;
        margin: -45px auto 0 auto;
    }*/

.person .person-contents {
    padding: 30px;
}

.person .person-contents h2,
.person .person-contents .h2 {
    font-size: 20px;
}

.person .person-contents h2 a,
.person .person-contents .h2 a {
    color: #000;
    text-decoration: none;
}

.person .person-contents p {
    font-size: 15px;
}


.AdminGal {
    width: 100%;
    background-color: #f7f7f7;
    border-radius: 2px;
    overflow: hidden;
}

.AdminGal1 {
    width: 100%;
    background-color: #ffffff;
    transition: 200ms;
    border-radius: 2px;
    overflow: hidden;
}

.AdminGal1:hover {
    box-shadow: 0px 0 40px rgb(0 0 0 / 0.14);
}

.AdminGal p {
    line-height: 20px;
    margin: 10px 0;
    text-align: justify;
}

.imageCover {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.imageCover2 {
    width: 100%;
    height: 290px;
    background-size: cover;
    background-position: center;

}

.imageCover3 {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-position: center;

}

.imageCover2:hover {}

.textCase {
    padding: 10px;
}

.textCase1 {
    padding: 10px 20px;
}

.link-acent {
    color: #353535;
    cursor: pointer;
}

.link-acent:hover {
    color: #296d5c;
}

.donateDiv {
    padding: 50px 30px;
    background-color: white;
    margin: 0 auto;
}

.imageDate {
    width: 100%;
    height: 60px;
    background: linear-gradient(to left bottom, rgb(0 0 0 / 0.00) 40%, rgb(0 0 0 / 0.72));
    margin-top: -60px;
    padding-left: 10px;
    padding-bottom: 6px;
    display: flex;
    align-items: end;
}

/********************************* Login *********/
.logInMContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.logInDiv {
    width: 550px;
    min-height: 500px;
    padding: 50px 30px;
    background-color: rgb(255 255 255 / 0.70);
    backdrop-filter: blur(25px);
    border-radius: 20px;
    margin: -280px 0 50px 0;
    z-index: 200;
}

.logInDiv label {
    margin-top: 20px;
    color: #222222;
}

/********************************************** Slider */

.sliderContainer {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.sliderBox1 {
    width: 400%;
    height: 100%;
    margin: 0;
    display: flex;
    position: relative;
    animation: slideAnimate 17s infinite ease-in-out;
}

.slideImage1 {
    width: 25%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.overIt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgb(0 0 0 / 0.45);
    position: absolute;
    top: 0;
    text-align: center;
}


@keyframes slideAnimate {
    0% {
        left: 0;
    }

    27% {
        left: 0;
    }

    33.3% {
        left: -100%;
    }

    61% {
        left: -100%;
    }

    66.6% {
        left: -200%;
    }

    95% {
        left: -200%;
    }

    100% {
        left: -300%;
    }

}

/* */
.shadow-box {
    box-shadow: 0 2px 40px #09090939;
    border-radius: 5px;
}

.afterAbsolute {
    display: flex;
    justify-content: center;
    width: 100%;
}

.absolute {
    position: fixed;
    top: 15px;
    display: flex;
    align-self: center;

}

.onHover {
    transition: 100ms;
}

.onHover:hover {
    cursor: pointer;
    opacity: 0.7;
}

/* conformaition====================== */
.confirmContainer {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    background-color: #eaeaea;
}

.confirmBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 650px;
    height: 450px;
    background-color: white;
    margin-top: -200px;
    z-index: 1;
    padding: 30px;
    border-radius: 4px;
    justify-content: space-evenly;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.101);
}

.confirmBox img {
    width: 160px;
}








/* conformaition====================== */

@media (max-width:991px) {
    .sliderContainer {
        height: 80vh;
    }

}

@media (max-width:768px) {
    .sliderContainer {
        height: 600px;
    }

}



/****************** Responsive at 575px *********/
@media (max-width:575px) {
    .bosterHeading {
        font-size: 60px;
    }
}

.trs-100 {
    transition: 100ms;

}