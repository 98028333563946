body {
    margin:0px;
    padding:0px;
}

.galleryContainer{
    overflow:hidden;
}

.galleryDiv {

    margin-bottom: 100px;
}


.galleryImg{
    width:100%;
    height:250px;
    border-radius:8px;
    overflow:hidden;
}

.galleryImg img{
    width:100%;
    height:100%;
}

.galleryTitle {
    width: 100%;
    height: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 50px;
    font-weight: 600;
    margin-top: 10px;
}

    .galleryTitle a {
        color: #000000;
    }

.galleryDate {
    width: 100%;
    height: 50px;
    text-align: center;
}

.galleryImg2{
    width:100%;
    height:250px;
}

.galleryImg2 img{
    width:100%;
    height:100%;
}
.footerH {
    color: #5cc9e4;
}